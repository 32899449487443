import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const AnnotationsSidebar = ({
  pins,
  onSelect,
  onDelete,
  itemId,
  handleCheckboxChange,
}) => {
  return (
    <div className="absolute right-0 top-16 w-64 md:w-44 h-full bg-gray-100 overflow-y-auto shadow-lg z-20">
      {/* Annotations List */}
      <ul className="list-none m-0 p-0">
        {pins.map((pin, index) => (
          <li
            key={index}
            onClick={() => onSelect(pin)}
            className="cursor-pointer p-4 hover:bg-blue-100 transition-colors duration-150 ease-in-out flex justify-between items-center"
          >
            <div className="flex items-center justify-start w-full">
              {/* Text content */}
              <div>
                <div className="font-medium md:font-normal md:text-sm text-gray-800">
                  {pin.annotation}
                </div>
                <div className="text-xs md:font-light text-gray-600 truncate">
                  Annotation {index + 1}
                </div>
              </div>
            </div>
            {/* Buttons */}
            <div className="flex items-center">
              {/* Show/Hide Toggle */}
              <label
                className="inline-flex items-center cursor-pointer"
                onClick={(e) => {
                  // Stop propagation of the click event
                  e.stopPropagation();
                }}
              >
                <input
                  type="checkbox"
                  value={pin.show ? "true" : "false"}
                  className="sr-only peer"
                  checked={pin.show}
                  onChange={(e) => {
                    handleCheckboxChange(e.target.checked, pin.id);
                  }}
                />
                <div className="relative w-7 h-4 md:w-5 md:h-3 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full md:after:h-2 md:after:w-2 after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
              {/* Delete Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(index, itemId, pin.id);
                }}
                className="px-2 py-1 md:p-0 bg-red-500 text-white font-semibold md:font-normal text-xs rounded hover:bg-red-600 transition-colors duration-150 ease-in-out ml-2 md:h-8 md:w-8"
              >
                <DeleteOutlineIcon />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AnnotationsSidebar;
