import { useState, useEffect } from "react";
import { database } from "../firebase";
import { ref, set, push } from "firebase/database";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddCasePageView = () => {
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [anatomy, setAnatomy] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [creationDate, setCreationDate] = useState(new Date());
  const [expirationDate, setExpirationDate] = useState(null);
  const [creator, setCreator] = useState("");
  const [error, setError] = useState(null);

  // Add mobile detection
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (user) {
      const initialCreator = user.firstName + " " + user.lastName;
      setCreator(initialCreator);
    }
  }, [user]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!title || !anatomy || !dueDate || !expirationDate || !creator) {
      setError("Please fill in all required fields");
      return;
    }

    if (!user || !user.id) {
      setError("User is not loaded correctly");
      return;
    }

    try {
      const newCaseKey = push(ref(database, `users/${user.id}/cases`)).key;
      const caseRef = ref(database, `users/${user.id}/cases/${newCaseKey}`);

      const caseData = {
        id: newCaseKey,
        title,
        anatomy,
        dueDate: dueDate.toDateString(),
        creationDate: creationDate.toDateString(),
        expirationDate: expirationDate.toDateString(),
        creator,
        status: "UPLOAD DICOM",
      };

      await set(caseRef, caseData);
      setError(null);
      navigate("/caselist");
    } catch (err) {
      console.error("Error writing new case to Firebase Database", err);
      setError("Error saving case");
    }
  };

  // Mobile View
  if (isMobile) {
    return (
      <div className="px-4 pt-16 pb-8 overflow-auto">
        <div className="p-8 rounded-xl shadow-md shadow-blue-100 bg-blue-100/50 w-full max-w-lg mx-auto">
          <h2 className="text-2xl font-bold mb-6 pb-6 border-b-2 text-center border-blue-500 text-blue-500">
            Add New Case
          </h2>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <form className="space-y-4" onSubmit={onSubmit}>
            <div>
              <label
                htmlFor="title"
                className="block text-gray-700 text-sm font-semibold mb-1"
              >
                Title
              </label>
              <input
                id="title"
                type="text"
                className="w-full px-3 py-2 mt-1 shadow bg-white hover:bg-gray-50 focus:bg-gray-50 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                placeholder="Enter title"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="anatomy"
                className="block text-gray-700 text-sm font-semibold mb-1"
              >
                Anatomy
              </label>
              <input
                id="anatomy"
                type="text"
                className="w-full px-3 py-2 mt-1 shadow bg-white hover:bg-gray-50 focus:bg-gray-50 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                placeholder="Enter anatomy"
                required
                value={anatomy}
                onChange={(e) => setAnatomy(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="creator"
                className="block text-gray-700 text-sm font-semibold mb-1"
              >
                Creator
              </label>
              <input
                id="creator"
                type="text"
                className="w-full px-3 py-2 mt-1 shadow bg-white hover:bg-gray-50 focus:bg-gray-50 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                placeholder="Enter creator"
                required
                value={creator}
                onChange={(e) => setCreator(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="dueDate"
                className="block text-gray-700 text-sm font-semibold mb-1"
              >
                Due Date
              </label>
              <DatePicker
                selected={dueDate}
                onChange={(date) => setDueDate(date)}
                dateFormat="dd/MM/yyyy"
                className="w-full px-3 py-2 mt-1 shadow bg-white hover:bg-gray-50 focus:bg-gray-50 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                placeholderText="Select due date"
                required
              />
            </div>
            <div>
              <label
                htmlFor="expirationDate"
                className="block text-gray-700 text-sm font-semibold mb-1"
              >
                Expiration Date
              </label>
              <DatePicker
                selected={expirationDate}
                onChange={(date) => setExpirationDate(date)}
                dateFormat="dd/MM/yyyy"
                className="w-full px-3 py-2 mt-1 shadow bg-white hover:bg-gray-50 focus:bg-gray-50 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
                placeholderText="Select expiration date"
                required
              />
            </div>
            <div>
              <label
                htmlFor="creationDate"
                className="block text-gray-700 text-sm font-semibold mb-1"
              >
                Creation Date
              </label>
              <DatePicker
                selected={creationDate}
                onChange={(date) => setCreationDate(date)}
                dateFormat="dd/MM/yyyy"
                className="w-full px-3 py-2 mt-1 shadow bg-white hover:bg-gray-50 focus:bg-gray-50 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md font-bold"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }

  // Desktop View (unchanged)
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-blue-500">
          Add New Case
        </h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form className="space-y-4" onSubmit={onSubmit}>
          <div>
            <label
              htmlFor="title"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Title
            </label>
            <input
              id="title"
              type="text"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter title"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="anatomy"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Anatomy
            </label>
            <input
              id="anatomy"
              type="text"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter anatomy"
              required
              value={anatomy}
              onChange={(e) => setAnatomy(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="creator"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Creator
            </label>
            <input
              id="creator"
              type="text"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter creator"
              required
              value={creator}
              onChange={(e) => setCreator(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="dueDate"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Due Date
            </label>
            <DatePicker
              selected={dueDate}
              onChange={(date) => setDueDate(date)}
              dateFormat="dd/MM/yyyy"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholderText="Select due date"
              required
            />
          </div>
          <div>
            <label
              htmlFor="expirationDate"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Expiration Date
            </label>
            <DatePicker
              selected={expirationDate}
              onChange={(date) => setExpirationDate(date)}
              dateFormat="dd/MM/yyyy"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholderText="Select expiration date"
              required
            />
          </div>
          <div>
            <label
              htmlFor="creationDate"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Creation Date
            </label>
            <DatePicker
              selected={creationDate}
              onChange={(date) => setCreationDate(date)}
              dateFormat="dd/MM/yyyy"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddCasePageView;
