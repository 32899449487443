const MeasurementsSidebar = ({ distance }) => {
  return (
    <div className="absolute right-0 top-16 w-64 md:w-44 h-full bg-gray-100 overflow-y-auto shadow-lg z-20">
      <div className="px-6 py-4">
        <div className="text-lg md:text-base font-semibold text-gray-800 mb-4">
          Total Distance:
        </div>
        <div className="text-2xl md:text-lg font-bold text-gray-900">
          {distance} mm
        </div>
      </div>
    </div>
  );
};

export default MeasurementsSidebar;
