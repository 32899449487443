import SquareFootIcon from "@mui/icons-material/SquareFoot";
import LabelIcon from "@mui/icons-material/Label";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import CloseIcon from "@mui/icons-material/Close";

const Tabs = ({ activeTab, onTabClick, setIsSidebarOpen }) => {
  return (
    <div className="absolute right-0 top-0 h-full bg-gray-100 overflow-y-auto shadow-lg w-64 md:w-44 transition-all duration-300 ease-in-out">
      <div className="flex flex-row">
        <button
          onClick={() => onTabClick("models")}
          className={`flex items-center justify-center py-2 px-4 md:w-10 transition-colors duration-300 ${
            activeTab === "models"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          }`}
        >
          <ViewInArIcon />
        </button>
        <button
          onClick={() => onTabClick("annotations")}
          className={`flex items-center justify-center py-2 px-4 md:w-10 transition-colors duration-300 ${
            activeTab === "annotations"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          }`}
        >
          <LabelIcon />
        </button>
        <button
          onClick={() => onTabClick("measurements")}
          className={`flex items-center justify-center py-2 px-4 md:w-10 transition-colors duration-300 ${
            activeTab === "measurements"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          }`}
        >
          <SquareFootIcon />
        </button>
        <button
          onClick={() => setIsSidebarOpen(false)}
          className="flex items-center justify-center p-1 transition-colors md:w-8 duration-300 bg-gray-800 text-white"
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default Tabs;
