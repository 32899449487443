import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentUser } from "../redux/userRedux";
import { ref, update } from "firebase/database";
import { database } from "../firebase";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const ProfilePageView = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [formData, setFormData] = useState({
    firstName: currentUser?.firstName || "",
    lastName: currentUser?.lastName || "",
    email: currentUser?.email || "",
    number: currentUser?.number || "",
    department: currentUser?.department || "",
    hospitalName: currentUser?.hospitalName || "",
  });
  const [originalData, setOriginalData] = useState(formData);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fieldLabels = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    number: "Phone Number",
    department: "Department",
    hospitalName: "Hospital Name",
  };

  const formatName = (name) => {
    return name
      .split(" ")
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = formatName(value);

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      number: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setCurrentUser(formData));
    setIsEditing(false);

    const userRef = ref(database, `users/${currentUser.id}`);
    await update(userRef, formData);
    setSuccess("Profile updated successfully.");
  };

  const handleCancel = () => {
    setFormData(originalData);
    setIsEditing(false);
  };

  const handleEdit = () => {
    setOriginalData(formData);
    setIsEditing(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);
    return parsedNumber ? parsedNumber.formatInternational() : phoneNumber;
  };

  // Mobile View
  if (isMobile) {
    return (
      <div className="p-4">
        <h1 className="text-lg font-semibold mb-4">Profile</h1>
        {success && <div className="text-green-500">{success}</div>}
        {isEditing ? (
          <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
            {Object.keys(formData).map((key) => (
              <div key={key} className="mb-4">
                <label className="block font-semibold">{fieldLabels[key]}</label>
                {key === "number" ? (
                  <PhoneInput
                    value={formData[key]}
                    onChange={handlePhoneChange}
                    inputStyle={{ width: "100%" }}
                    placeholder="Select your country and enter your phone number"
                  />
                ) : (
                  <input
                    type="text"
                    name={key}
                    value={formData[key]}
                    onChange={handleChange}
                    className="border p-2 w-full break-words"
                  />
                )}
              </div>
            ))}
            <div className="flex space-x-2">
              <button type="submit" className="px-4 py-2 bg-blue-500 text-white w-full">
                Save
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="px-4 py-2 bg-gray-500 text-white w-full"
              >
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <>
            {Object.keys(formData).map((key) => (
              <div key={key} className="mb-4">
                <label className="block font-semibold">{fieldLabels[key]}</label>
                <p className="break-words">
                  {key === "number" ? formatPhoneNumber(`+${formData[key]}`) : formData[key]}
                </p>
              </div>
            ))}
            <button
              onClick={handleEdit}
              className="mt-4 px-4 py-2 bg-blue-500 text-white w-full"
            >
              Edit
            </button>
          </>
        )}
      </div>
    );
  }

  // PC View (unchanged)
  return (
    <div className="p-8">
      <h1 className="text-lg font-semibold mb-4">Profile</h1>
      {success && <div className="text-green-500">{success}</div>}
      {isEditing ? (
        <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
          <table className="table-auto w-full">
            <tbody>
              {Object.keys(formData).map((key) => (
                <tr key={key} className="border-b">
                  <td className="px-3 py-2 font-semibold">
                    {fieldLabels[key]}
                  </td>
                  <td className="px-3 py-2">
                    {key === "number" ? (
                      <PhoneInput
                        value={formData[key]}
                        onChange={handlePhoneChange}
                        inputStyle={{ width: "100%" }}
                        placeholder="Select your country and enter your phone number"
                      />
                    ) : (
                      <input
                        type="text"
                        name={key}
                        value={formData[key]}
                        onChange={handleChange}
                        className="border p-1 w-full"
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex space-x-2 mt-4">
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white">
              Save
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="px-4 py-2 bg-gray-500 text-white"
            >
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <>
          <table className="table-auto w-full">
            <tbody>
              {Object.keys(formData).map((key) => (
                <tr key={key} className="border-b">
                  <td className="px-3 py-2 font-semibold">
                    {fieldLabels[key]}
                  </td>
                  <td className="px-3 py-2">
                    {key === "number"
                      ? formatPhoneNumber(`+${formData[key]}`)
                      : formData[key]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            onClick={handleEdit}
            className="mt-4 px-4 py-2 bg-blue-500 text-white"
          >
            Edit
          </button>
        </>
      )}
    </div>
  );
};

export default ProfilePageView;
